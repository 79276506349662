<template>
  <v-container fluid>
    <CRUD
      ref="crud"
      id-method="params"
      :fields="fields"
      :table="table"
      :api="api"
      @form:input="onFormInput"
      @changed:mode="resetForm"
    >
    </CRUD>
  </v-container>
</template>

<script>
import CRUD from "@/components/util/CRUD/CRUD";

export default {
  components: {
    CRUD
  },
  data() {
    return {
      api: {
        url: "/ccms/table/",
        params: {
          tableName: "cnetd.timesheet"
        }
      },
      table: {
        text: "Timesheet",
        key: "TimesheetNo",
        nameIdentifier: "NRIC",
        default: {
          itemsPerPage: 20,
          sortBy: ["LogIn"],
          sortDesc: [true]
        },
        components: {
          bulkEdit: true,
          quickFilter: [
            {
              name: "LogIn",
              text: "Date Range",
              width: "300px",
              type: "date-range",
              value: [new Date().toJSON().slice(0, 10), new Date().toJSON().slice(0, 10)]
            },
            {
              name: "NRIC",
              width: "250px",
              multiple: true
            },
            {
              name: "ProjectID",
              width: "300px",
              multiple: true
            }
          ]
        }
      },
      fields: [
        {
          text: "TimesheetNo",
          name: "TimesheetNo",
          editable: false,
          listable: false
        },
        {
          text: "Name",
          name: "NRIC",
          searchable: true,
          input: {
            type: "L",
            validation: ["mandatory"]
          },
          foreign: {
            table: "cnetd.tempstaff",
            key: "NRIC",
            display: "TName",
            where: `AreaID = '${this.$store.state.auth.user.areaid}'`
          }
        },
        {
          text: "LogIn",
          name: "LogIn",
          searchable: true,
          bulkEditable: true,
          input: {
            type: "D",
            control: "DateTime",
            validation: ["mandatory"],
            bind: { allowedMinutes: (m) => m % 15 === 0 }
          }
        },
        {
          text: "LogOut",
          name: "LogOut",
          searchable: true,
          bulkEditable: true,
          input: {
            type: "D",
            control: "DateTime",
            validation: ["mandatory"],
            bind: { allowedMinutes: (m) => m % 15 === 0 }
          }
        },
        {
          text: "Project Name",
          name: "ProjectID",
          searchable: true,
          input: {
            type: "L",
            validation: ["mandatory"]
          },
          foreign: {
            table: "cnetd.project",
            key: "ProjectID",
            display: "ProjectNM",
            where: `AreaID = '${this.$store.state.auth.user.areaid}'`
          }
        },
        {
          text: "Lunch Hour",
          name: "LunchHour",
          searchable: true,
          bulkEditable: true,
          input: {
            type: "L",
            validation: ["mandatory"],
            notranslate: true,
            bind: { errorMessages: null }
          },
          foreign: {
            table: "cnetd.timesheet_lunch",
            key: "value",
            display: "name"
          }
        },
        {
          text: "Hours Worked",
          name: "HourWorked",
          searchable: true,
          input: {
            disabled: true
          }
        },
        {
          text: "Create By",
          name: "CreateBy",
          listable: false,
          editable: false
        },
        {
          text: "Created Date",
          name: "CreateDate",
          listable: false,
          editable: false
        },
        {
          text: "Updated By",
          name: "UpdateBy",
          listable: false,
          editable: false,
          searchable: true
        },
        {
          text: "Updated Date",
          name: "UpdateDate",
          listable: false,
          editable: false
        }
      ]
    };
  },
  methods: {
    onFormInput(e) {
      if (["LogIn", "LogOut", "LunchHour"].includes(e.item.field)) {
        this.resetForm();
        if (e.values.LogOut && e.values.LogIn) {
          let ret = checkDate(e);
          if (ret.error) {
            this.fields.find((x) => x.name == ret.prompt_err_on).input.bind.errorMessages =
              ret.error;
          } else {
            if (ret.hoursWorked) {
              this.$refs.crud.updateValue("HourWorked", ret.hoursWorked);
            }
          }
        }
      }
    },
    resetForm(e) {
      if (e != "list") {
        for (let field of Object.values(["LogIn", "LogOut", "LunchHour"])) {
          this.fields.find((x) => x.name == field).input.bind.errorMessages = null;
        }
      }
    }
  }
};

function checkDate(e) {
  if (e.values.LogOut && e.values.LogIn) {
    if (e.values.LogOut.slice(0, 10) != e.values.LogIn.slice(0, 10)) {
      return { error: "LogIn and LogOut date must be the same date.", prompt_err_on: "LogOut" };
    }
    var edate1 = Date.parse(e.values.LogOut);
    var sdate1 = Date.parse(e.values.LogIn);
    var diff = edate1 - sdate1;
    if (diff < 0) {
      return { error: "LogOut Time must be greater than LogIn Time", prompt_err_on: "LogOut" };
    }

    //get lunch hour
    var lunch_hour = e.values.LunchHour;
    //alert("lunch hour=="+  lunch_hour);

    var hourworked = diff - lunch_hour * 3600000;
    if ((lunch_hour == 0 || lunch_hour == null) && hourworked == 0) {
      return { error: "LogOut Time must be greater than LogIn Time", prompt_err_on: "LogOut" };
    } else if (hourworked <= 0 && lunch_hour > 0) {
      return {
        error: "Lunch Hour cannot be greater than or equal to Hours Worked",
        prompt_err_on: "LunchHour"
      };
    }
    //now check lunchhour
    var netHrsWorked = ((hourworked / 3600000) * 100) / 100;
    netHrsWorked = isNaN(netHrsWorked) ? null : netHrsWorked;

    return { error: false, hoursWorked: netHrsWorked };
  }
}
</script>
